import React from 'react';

import Image from '../../utils/OptimizedImage';

import {
  Card,
  Date,
  Icon,
  Heading,
  Subhead,
} from './styles/HistoryCard.styled';

const HistoryCard = ({
  image,
  imageDescription,
  heading,
  subhead,
  internalName,
}) => (
  <Card>
    <Icon>
      {image && (
        <Image
          image={image?.gatsbyImageData}
          src={image?.url}
          alt={image?.alt}
          title={image?.title}
        />
      )}
    </Icon>
    <Date>{imageDescription}</Date>
    <Heading>{heading}</Heading>
    <Subhead dangerouslySetInnerHTML={{ __html: subhead }} />
  </Card>
);

export default HistoryCard;
