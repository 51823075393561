import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  text-align: left;
  background: ${colors.base.white};
  max-width: 314px;
  height: 292px;
  max-height: 292px;
  border-radius: 12px;
  padding: 28px;
  ${shadow('md')}
  margin: 5px 16px;
`;

export const Icon = styled.div`
  margin-bottom: 8px;

  img {
    max-width: 30px;
    max-height: 30px;
  }
`;

export const Date = styled.p`
  ${font('overline', 'md', '700')}
  color: ${colors.green[500]};
  margin-bottom: 18px;
`;

export const Heading = styled.h3`
  ${font('display', 'xs', '700')}
  color: ${colors.gray[900]};
  margin-bottom: 9px;
`;

export const Subhead = styled.div`
  p{
    ${font('text', 'sm', '400')}
    color: ${colors.gray[700]};
  }
`;
